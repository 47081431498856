/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { TimelogResponseDto } from '../../models/timelog-response-dto';
import { TimelogSuggestInputDto } from '../../models/timelog-suggest-input-dto';

export interface TimelogSuggest$Params {
  body: TimelogSuggestInputDto;
}

export function timelogSuggest(
  http: HttpClient,
  rootUrl: string,
  params: TimelogSuggest$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<TimelogResponseDto>> {
  const rb = new RequestBuilder(rootUrl, timelogSuggest.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TimelogResponseDto>;
    }),
  );
}

timelogSuggest.PATH = '/timelog/timelog-suggest';
