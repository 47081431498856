/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { QueryInstanceResult } from '../../models/query-instance-result';
import { TimelogQueryDto } from '../../models/timelog-query-dto';

export interface TimelogAccountingInstancesQuery$Params {
  body: TimelogQueryDto;
}

export function timelogAccountingInstancesQuery(
  http: HttpClient,
  rootUrl: string,
  params: TimelogAccountingInstancesQuery$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<QueryInstanceResult>> {
  const rb = new RequestBuilder(rootUrl, timelogAccountingInstancesQuery.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<QueryInstanceResult>;
    }),
  );
}

timelogAccountingInstancesQuery.PATH = '/timelog/timelog-accounting-instances-query';
