/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { StrictHttpResponse } from '../strict-http-response';

import { AgentAccessible$Params, agentAccessible } from '../fn/operations/agent-accessible';
import { AgentGender$Params, agentGender } from '../fn/operations/agent-gender';
import { AgentQuery$Params, agentQuery } from '../fn/operations/agent-query';
import { AgentRemoveShare$Params, agentRemoveShare } from '../fn/operations/agent-remove-share';
import { AgentSave$Params, agentSave } from '../fn/operations/agent-save';
import { AgentSetPassword$Params, agentSetPassword } from '../fn/operations/agent-set-password';
import { AgentShareTo$Params, agentShareTo } from '../fn/operations/agent-share-to';
import { AgentSharedTo$Params, agentSharedTo } from '../fn/operations/agent-shared-to';
import { AgentValidateEmail$Params, agentValidateEmail } from '../fn/operations/agent-validate-email';
import {
  AgentValidatePersonnelNo$Params,
  agentValidatePersonnelNo,
} from '../fn/operations/agent-validate-personnel-no';
import { AppControllerGetHello$Params, appControllerGetHello } from '../fn/operations/app-controller-get-hello';
import { AuthEnable2Fa$Params, authEnable2Fa } from '../fn/operations/auth-enable-2-fa';
import { AuthLogin$Params, authLogin } from '../fn/operations/auth-login';
import { AuthLogout$Params, authLogout } from '../fn/operations/auth-logout';
import { AuthMe$Params, authMe } from '../fn/operations/auth-me';
import { GetAllSettings$Params, getAllSettings } from '../fn/operations/get-all-settings';
import { GetSetting$Params, getSetting } from '../fn/operations/get-setting';
import { InstanceDelete$Params, instanceDelete } from '../fn/operations/instance-delete';
import { InstancePeriodsStats$Params, instancePeriodsStats } from '../fn/operations/instance-periods-stats';
import { InstanceQuery$Params, instanceQuery } from '../fn/operations/instance-query';
import { InstanceQueryTeamlead$Params, instanceQueryTeamlead } from '../fn/operations/instance-query-teamlead';
import { InstanceSave$Params, instanceSave } from '../fn/operations/instance-save';
import { InstanceTypeDelete$Params, instanceTypeDelete } from '../fn/operations/instance-type-delete';
import { InstanceTypeQuery$Params, instanceTypeQuery } from '../fn/operations/instance-type-query';
import { InstanceTypeSave$Params, instanceTypeSave } from '../fn/operations/instance-type-save';
import { InstanceUpdateRegion$Params, instanceUpdateRegion } from '../fn/operations/instance-update-region';
import {
  InstanceWageChargeDelete$Params,
  instanceWageChargeDelete,
} from '../fn/operations/instance-wage-charge-delete';
import { InstanceWageChargeSave$Params, instanceWageChargeSave } from '../fn/operations/instance-wage-charge-save';
import { InstanceWageDelete$Params, instanceWageDelete } from '../fn/operations/instance-wage-delete';
import { InstanceWageQuery$Params, instanceWageQuery } from '../fn/operations/instance-wage-query';
import { InstanceWageSave$Params, instanceWageSave } from '../fn/operations/instance-wage-save';
import { InvoiceAgendaList$Params, invoiceAgendaList } from '../fn/operations/invoice-agenda-list';
import { InvoiceBunch$Params, invoiceBunch } from '../fn/operations/invoice-bunch';
import { InvoiceBunchSave$Params, invoiceBunchSave } from '../fn/operations/invoice-bunch-save';
import { InvoiceCreate$Params, invoiceCreate } from '../fn/operations/invoice-create';
import { InvoiceDelete$Params, invoiceDelete } from '../fn/operations/invoice-delete';
import { InvoiceList$Params, invoiceList } from '../fn/operations/invoice-list';
import { InvoiceQuery$Params, invoiceQuery } from '../fn/operations/invoice-query';
import { InvoiceQueryCustomers$Params, invoiceQueryCustomers } from '../fn/operations/invoice-query-customers';
import { SaveSetting$Params, saveSetting } from '../fn/operations/save-setting';
import { TeamAccessible$Params, teamAccessible } from '../fn/operations/team-accessible';
import { TeamDelete$Params, teamDelete } from '../fn/operations/team-delete';
import { TeamQuery$Params, teamQuery } from '../fn/operations/team-query';
import { TeamSave$Params, teamSave } from '../fn/operations/team-save';
import { Timelog$Params, timelog } from '../fn/operations/timelog';
import {
  TimelogAccountingInstancesInvoices$Params,
  timelogAccountingInstancesInvoices,
} from '../fn/operations/timelog-accounting-instances-invoices';
import {
  TimelogAccountingInstancesQuery$Params,
  timelogAccountingInstancesQuery,
} from '../fn/operations/timelog-accounting-instances-query';
import {
  TimelogAccountingWarnings$Params,
  timelogAccountingWarnings,
} from '../fn/operations/timelog-accounting-warnings';
import { TimelogApprove$Params, timelogApprove } from '../fn/operations/timelog-approve';
import { TimelogAttachment$Params, timelogAttachment } from '../fn/operations/timelog-attachment';
import {
  TimelogBunchDocumentation$Params,
  timelogBunchDocumentation,
} from '../fn/operations/timelog-bunch-documentation';
import { TimelogBunchSave$Params, timelogBunchSave } from '../fn/operations/timelog-bunch-save';
import { TimelogCollisions$Params, timelogCollisions } from '../fn/operations/timelog-collisions';
import { TimelogDelete$Params, timelogDelete } from '../fn/operations/timelog-delete';
import { TimelogImage$Params, timelogImage } from '../fn/operations/timelog-image';
import { TimelogMonthLock$Params, timelogMonthLock } from '../fn/operations/timelog-month-lock';
import { TimelogMonthLocks$Params, timelogMonthLocks } from '../fn/operations/timelog-month-locks';
import { TimelogMonthUnlock$Params, timelogMonthUnlock } from '../fn/operations/timelog-month-unlock';
import { TimelogQuery$Params, timelogQuery } from '../fn/operations/timelog-query';
import { TimelogSave$Params, timelogSave } from '../fn/operations/timelog-save';
import { TimelogSuggest$Params, timelogSuggest } from '../fn/operations/timelog-suggest';
import { TimelogUnapprove$Params, timelogUnapprove } from '../fn/operations/timelog-unapprove';
import { TimelogValidate$Params, timelogValidate } from '../fn/operations/timelog-validate';
import {
  TimelogWithInstancesQuery$Params,
  timelogWithInstancesQuery,
} from '../fn/operations/timelog-with-instances-query';
import { AccountingWarningsDto } from '../models/accounting-warnings-dto';
import { AgentEntity } from '../models/agent-entity';
import { AgentEntityDto } from '../models/agent-entity-dto';
import { AgentShareDeleteResult } from '../models/agent-share-delete-result';
import { AgentShareToResult } from '../models/agent-share-to-result';
import { AuthDto } from '../models/auth-dto';
import { AuthMeDto } from '../models/auth-me-dto';
import { DeleteResponseDto } from '../models/delete-response-dto';
import { InstanceDto } from '../models/instance-dto';
import { InstanceEntity } from '../models/instance-entity';
import { InstancePeriodDto } from '../models/instance-period-dto';
import { InstanceTypeDto } from '../models/instance-type-dto';
import { InstanceWageChargeDto } from '../models/instance-wage-charge-dto';
import { InstanceWageDto } from '../models/instance-wage-dto';
import { InvoiceBunchDto } from '../models/invoice-bunch-dto';
import { InvoiceDto } from '../models/invoice-dto';
import { MonthLockEntity } from '../models/month-lock-entity';
import { QueryAgentResult } from '../models/query-agent-result';
import { QueryInstanceResult } from '../models/query-instance-result';
import { QueryInstanceTypeResult } from '../models/query-instance-type-result';
import { QueryInstanceWageResult } from '../models/query-instance-wage-result';
import { QueryInvoiceResult } from '../models/query-invoice-result';
import { QueryMonthLocksResult } from '../models/query-month-locks-result';
import { QueryTeamResult } from '../models/query-team-result';
import { QueryTimelogResult } from '../models/query-timelog-result';
import { QueryTimelogWithInstancesResult } from '../models/query-timelog-with-instances-result';
import { QueryValidateResult } from '../models/query-validate-result';
import { SettingDto } from '../models/setting-dto';
import { TeamEntity } from '../models/team-entity';
import { TimelogApproveInputDto } from '../models/timelog-approve-input-dto';
import { TimelogAttachment } from '../models/timelog-attachment';
import { TimelogBunchDto } from '../models/timelog-bunch-dto';
import { TimelogEntity } from '../models/timelog-entity';
import { TimelogMonthLockInputDto } from '../models/timelog-month-lock-input-dto';
import { TimelogResponseDto } from '../models/timelog-response-dto';
import { TimelogValidateResultDto } from '../models/timelog-validate-result-dto';
import { TwoFactorEnablingResult } from '../models/two-factor-enabling-result';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `appControllerGetHello()` */
  static readonly AppControllerGetHelloPath = '/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appControllerGetHello()` instead.
   *
   * This method doesn't expect any request body.
   */
  appControllerGetHello$Response(
    params?: AppControllerGetHello$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<string>> {
    return appControllerGetHello(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appControllerGetHello$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appControllerGetHello(params?: AppControllerGetHello$Params, context?: HttpContext): Observable<string> {
    return this.appControllerGetHello$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body),
    );
  }

  /** Path part for operation `authLogin()` */
  static readonly AuthLoginPath = '/auth/auth-login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin$Response(params: AuthLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthDto>> {
    return authLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogin(params: AuthLogin$Params, context?: HttpContext): Observable<AuthDto> {
    return this.authLogin$Response(params, context).pipe(map((r: StrictHttpResponse<AuthDto>): AuthDto => r.body));
  }

  /** Path part for operation `authLogout()` */
  static readonly AuthLogoutPath = '/auth/auth-logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authLogout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogout$Response(params: AuthLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthDto>> {
    return authLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authLogout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authLogout(params: AuthLogout$Params, context?: HttpContext): Observable<AuthDto> {
    return this.authLogout$Response(params, context).pipe(map((r: StrictHttpResponse<AuthDto>): AuthDto => r.body));
  }

  /** Path part for operation `authMe()` */
  static readonly AuthMePath = '/auth/auth-me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  authMe$Response(params?: AuthMe$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthMeDto>> {
    return authMe(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authMe(params?: AuthMe$Params, context?: HttpContext): Observable<AuthMeDto> {
    return this.authMe$Response(params, context).pipe(map((r: StrictHttpResponse<AuthMeDto>): AuthMeDto => r.body));
  }

  /** Path part for operation `authEnable2Fa()` */
  static readonly AuthEnable2FaPath = '/auth/auth-enable-2fa';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authEnable2Fa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authEnable2Fa$Response(
    params: AuthEnable2Fa$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TwoFactorEnablingResult>> {
    return authEnable2Fa(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authEnable2Fa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authEnable2Fa(params: AuthEnable2Fa$Params, context?: HttpContext): Observable<TwoFactorEnablingResult> {
    return this.authEnable2Fa$Response(params, context).pipe(
      map((r: StrictHttpResponse<TwoFactorEnablingResult>): TwoFactorEnablingResult => r.body),
    );
  }

  /** Path part for operation `agentQuery()` */
  static readonly AgentQueryPath = '/agent/agent-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentQuery$Response(
    params: AgentQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryAgentResult>> {
    return agentQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentQuery(params: AgentQuery$Params, context?: HttpContext): Observable<QueryAgentResult> {
    return this.agentQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryAgentResult>): QueryAgentResult => r.body),
    );
  }

  /** Path part for operation `agentAccessible()` */
  static readonly AgentAccessiblePath = '/agent/agent-accessible';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentAccessible()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentAccessible$Response(
    params: AgentAccessible$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryAgentResult>> {
    return agentAccessible(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentAccessible$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentAccessible(params: AgentAccessible$Params, context?: HttpContext): Observable<QueryAgentResult> {
    return this.agentAccessible$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryAgentResult>): QueryAgentResult => r.body),
    );
  }

  /** Path part for operation `agentGender()` */
  static readonly AgentGenderPath = '/agent/agent-gender';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentGender()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentGender$Response(
    params?: AgentGender$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<string>>> {
    return agentGender(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentGender$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentGender(params?: AgentGender$Params, context?: HttpContext): Observable<Array<string>> {
    return this.agentGender$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body),
    );
  }

  /** Path part for operation `agentSharedTo()` */
  static readonly AgentSharedToPath = '/agent/agent-shared-to';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentSharedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentSharedTo$Response(
    params?: AgentSharedTo$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryAgentResult>> {
    return agentSharedTo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentSharedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentSharedTo(params?: AgentSharedTo$Params, context?: HttpContext): Observable<QueryAgentResult> {
    return this.agentSharedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryAgentResult>): QueryAgentResult => r.body),
    );
  }

  /** Path part for operation `agentValidateEmail()` */
  static readonly AgentValidateEmailPath = '/agent/agent-validate-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentValidateEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentValidateEmail$Response(
    params: AgentValidateEmail$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryValidateResult>> {
    return agentValidateEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentValidateEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentValidateEmail(params: AgentValidateEmail$Params, context?: HttpContext): Observable<QueryValidateResult> {
    return this.agentValidateEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryValidateResult>): QueryValidateResult => r.body),
    );
  }

  /** Path part for operation `agentValidatePersonnelNo()` */
  static readonly AgentValidatePersonnelNoPath = '/agent/agent-validate-personnel-no';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentValidatePersonnelNo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentValidatePersonnelNo$Response(
    params: AgentValidatePersonnelNo$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryValidateResult>> {
    return agentValidatePersonnelNo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentValidatePersonnelNo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentValidatePersonnelNo(
    params: AgentValidatePersonnelNo$Params,
    context?: HttpContext,
  ): Observable<QueryValidateResult> {
    return this.agentValidatePersonnelNo$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryValidateResult>): QueryValidateResult => r.body),
    );
  }

  /** Path part for operation `agentShareTo()` */
  static readonly AgentShareToPath = '/agent/agent-share-to';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentShareTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentShareTo$Response(
    params?: AgentShareTo$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AgentShareToResult>> {
    return agentShareTo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentShareTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentShareTo(params?: AgentShareTo$Params, context?: HttpContext): Observable<AgentShareToResult> {
    return this.agentShareTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentShareToResult>): AgentShareToResult => r.body),
    );
  }

  /** Path part for operation `agentSetPassword()` */
  static readonly AgentSetPasswordPath = '/agent/agent-set-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentSetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentSetPassword$Response(
    params: AgentSetPassword$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AgentEntity>> {
    return agentSetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentSetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentSetPassword(params: AgentSetPassword$Params, context?: HttpContext): Observable<AgentEntity> {
    return this.agentSetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentEntity>): AgentEntity => r.body),
    );
  }

  /** Path part for operation `agentRemoveShare()` */
  static readonly AgentRemoveSharePath = '/agent/agent-remove-share';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentRemoveShare()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentRemoveShare$Response(
    params?: AgentRemoveShare$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AgentShareDeleteResult>> {
    return agentRemoveShare(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentRemoveShare$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agentRemoveShare(params?: AgentRemoveShare$Params, context?: HttpContext): Observable<AgentShareDeleteResult> {
    return this.agentRemoveShare$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentShareDeleteResult>): AgentShareDeleteResult => r.body),
    );
  }

  /** Path part for operation `agentSave()` */
  static readonly AgentSavePath = '/agent/agent-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agentSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentSave$Response(params: AgentSave$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentEntityDto>> {
    return agentSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agentSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agentSave(params: AgentSave$Params, context?: HttpContext): Observable<AgentEntityDto> {
    return this.agentSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentEntityDto>): AgentEntityDto => r.body),
    );
  }

  /** Path part for operation `teamQuery()` */
  static readonly TeamQueryPath = '/team/team-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamQuery$Response(
    params?: TeamQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryTeamResult>> {
    return teamQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teamQuery(params?: TeamQuery$Params, context?: HttpContext): Observable<QueryTeamResult> {
    return this.teamQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryTeamResult>): QueryTeamResult => r.body),
    );
  }

  /** Path part for operation `teamAccessible()` */
  static readonly TeamAccessiblePath = '/team/team-accessible';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamAccessible()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamAccessible$Response(
    params: TeamAccessible$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryTeamResult>> {
    return teamAccessible(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamAccessible$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamAccessible(params: TeamAccessible$Params, context?: HttpContext): Observable<QueryTeamResult> {
    return this.teamAccessible$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryTeamResult>): QueryTeamResult => r.body),
    );
  }

  /** Path part for operation `teamSave()` */
  static readonly TeamSavePath = '/team/team-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamSave$Response(params: TeamSave$Params, context?: HttpContext): Observable<StrictHttpResponse<TeamEntity>> {
    return teamSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamSave(params: TeamSave$Params, context?: HttpContext): Observable<TeamEntity> {
    return this.teamSave$Response(params, context).pipe(map((r: StrictHttpResponse<TeamEntity>): TeamEntity => r.body));
  }

  /** Path part for operation `teamDelete()` */
  static readonly TeamDeletePath = '/team/team-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teamDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamDelete$Response(
    params: TeamDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return teamDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teamDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teamDelete(params: TeamDelete$Params, context?: HttpContext): Observable<DeleteResponseDto> {
    return this.teamDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `instanceQuery()` */
  static readonly InstanceQueryPath = '/instance/instance-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceQuery$Response(
    params: InstanceQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInstanceResult>> {
    return instanceQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceQuery(params: InstanceQuery$Params, context?: HttpContext): Observable<QueryInstanceResult> {
    return this.instanceQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInstanceResult>): QueryInstanceResult => r.body),
    );
  }

  /** Path part for operation `instanceQueryTeamlead()` */
  static readonly InstanceQueryTeamleadPath = '/instance/instance-query-teamlead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceQueryTeamlead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceQueryTeamlead$Response(
    params: InstanceQueryTeamlead$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInstanceResult>> {
    return instanceQueryTeamlead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceQueryTeamlead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceQueryTeamlead(params: InstanceQueryTeamlead$Params, context?: HttpContext): Observable<QueryInstanceResult> {
    return this.instanceQueryTeamlead$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInstanceResult>): QueryInstanceResult => r.body),
    );
  }

  /** Path part for operation `instanceUpdateRegion()` */
  static readonly InstanceUpdateRegionPath = '/instance/instance-update-region';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceUpdateRegion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceUpdateRegion$Response(
    params: InstanceUpdateRegion$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceDto>> {
    return instanceUpdateRegion(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceUpdateRegion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceUpdateRegion(params: InstanceUpdateRegion$Params, context?: HttpContext): Observable<InstanceDto> {
    return this.instanceUpdateRegion$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceDto>): InstanceDto => r.body),
    );
  }

  /** Path part for operation `instancePeriodsStats()` */
  static readonly InstancePeriodsStatsPath = '/instance/instance-periods-stats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instancePeriodsStats()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instancePeriodsStats$Response(
    params: InstancePeriodsStats$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<InstancePeriodDto>>> {
    return instancePeriodsStats(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instancePeriodsStats$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instancePeriodsStats(
    params: InstancePeriodsStats$Params,
    context?: HttpContext,
  ): Observable<Array<InstancePeriodDto>> {
    return this.instancePeriodsStats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InstancePeriodDto>>): Array<InstancePeriodDto> => r.body),
    );
  }

  /** Path part for operation `instanceSave()` */
  static readonly InstanceSavePath = '/instance/instance-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceSave$Response(
    params: InstanceSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceEntity>> {
    return instanceSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceSave(params: InstanceSave$Params, context?: HttpContext): Observable<InstanceEntity> {
    return this.instanceSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceEntity>): InstanceEntity => r.body),
    );
  }

  /** Path part for operation `instanceWageQuery()` */
  static readonly InstanceWageQueryPath = '/instance/instance-wage-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceWageQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageQuery$Response(
    params: InstanceWageQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInstanceWageResult>> {
    return instanceWageQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceWageQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageQuery(params: InstanceWageQuery$Params, context?: HttpContext): Observable<QueryInstanceWageResult> {
    return this.instanceWageQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInstanceWageResult>): QueryInstanceWageResult => r.body),
    );
  }

  /** Path part for operation `instanceWageSave()` */
  static readonly InstanceWageSavePath = '/instance/instance-wage-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceWageSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageSave$Response(
    params: InstanceWageSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceWageDto>> {
    return instanceWageSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceWageSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageSave(params: InstanceWageSave$Params, context?: HttpContext): Observable<InstanceWageDto> {
    return this.instanceWageSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceWageDto>): InstanceWageDto => r.body),
    );
  }

  /** Path part for operation `instanceWageDelete()` */
  static readonly InstanceWageDeletePath = '/instance/instance-wage-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceWageDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageDelete$Response(
    params: InstanceWageDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return instanceWageDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceWageDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageDelete(params: InstanceWageDelete$Params, context?: HttpContext): Observable<DeleteResponseDto> {
    return this.instanceWageDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `instanceWageChargeSave()` */
  static readonly InstanceWageChargeSavePath = '/instance/instance-wage-charge-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceWageChargeSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageChargeSave$Response(
    params: InstanceWageChargeSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceWageChargeDto>> {
    return instanceWageChargeSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceWageChargeSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageChargeSave(
    params: InstanceWageChargeSave$Params,
    context?: HttpContext,
  ): Observable<InstanceWageChargeDto> {
    return this.instanceWageChargeSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceWageChargeDto>): InstanceWageChargeDto => r.body),
    );
  }

  /** Path part for operation `instanceWageChargeDelete()` */
  static readonly InstanceWageChargeDeletePath = '/instance/instance-wage-charge-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceWageChargeDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageChargeDelete$Response(
    params: InstanceWageChargeDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return instanceWageChargeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceWageChargeDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceWageChargeDelete(
    params: InstanceWageChargeDelete$Params,
    context?: HttpContext,
  ): Observable<DeleteResponseDto> {
    return this.instanceWageChargeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `instanceTypeQuery()` */
  static readonly InstanceTypeQueryPath = '/instance/instance-type-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceTypeQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  instanceTypeQuery$Response(
    params?: InstanceTypeQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInstanceTypeResult>> {
    return instanceTypeQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceTypeQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  instanceTypeQuery(params?: InstanceTypeQuery$Params, context?: HttpContext): Observable<QueryInstanceTypeResult> {
    return this.instanceTypeQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInstanceTypeResult>): QueryInstanceTypeResult => r.body),
    );
  }

  /** Path part for operation `instanceTypeSave()` */
  static readonly InstanceTypeSavePath = '/instance/instance-type-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceTypeSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceTypeSave$Response(
    params: InstanceTypeSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceTypeDto>> {
    return instanceTypeSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceTypeSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceTypeSave(params: InstanceTypeSave$Params, context?: HttpContext): Observable<InstanceTypeDto> {
    return this.instanceTypeSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceTypeDto>): InstanceTypeDto => r.body),
    );
  }

  /** Path part for operation `instanceTypeDelete()` */
  static readonly InstanceTypeDeletePath = '/instance/instance-type-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceTypeDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceTypeDelete$Response(
    params: InstanceTypeDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return instanceTypeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceTypeDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceTypeDelete(params: InstanceTypeDelete$Params, context?: HttpContext): Observable<DeleteResponseDto> {
    return this.instanceTypeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `instanceDelete()` */
  static readonly InstanceDeletePath = '/instance/instance-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `instanceDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceDelete$Response(
    params: InstanceDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InstanceDto>> {
    return instanceDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `instanceDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  instanceDelete(params: InstanceDelete$Params, context?: HttpContext): Observable<InstanceDto> {
    return this.instanceDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<InstanceDto>): InstanceDto => r.body),
    );
  }

  /** Path part for operation `timelogImage()` */
  static readonly TimelogImagePath = '/timelog/image/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogImage$Response(params: TimelogImage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return timelogImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogImage(params: TimelogImage$Params, context?: HttpContext): Observable<void> {
    return this.timelogImage$Response(params, context).pipe(map((r: StrictHttpResponse<void>): void => r.body));
  }

  /** Path part for operation `timelogQuery()` */
  static readonly TimelogQueryPath = '/timelog/timelog-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogQuery$Response(
    params: TimelogQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryTimelogResult>> {
    return timelogQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogQuery(params: TimelogQuery$Params, context?: HttpContext): Observable<QueryTimelogResult> {
    return this.timelogQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryTimelogResult>): QueryTimelogResult => r.body),
    );
  }

  /** Path part for operation `timelogWithInstancesQuery()` */
  static readonly TimelogWithInstancesQueryPath = '/timelog/timelog-with-instances-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogWithInstancesQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogWithInstancesQuery$Response(
    params: TimelogWithInstancesQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryTimelogWithInstancesResult>> {
    return timelogWithInstancesQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogWithInstancesQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogWithInstancesQuery(
    params: TimelogWithInstancesQuery$Params,
    context?: HttpContext,
  ): Observable<QueryTimelogWithInstancesResult> {
    return this.timelogWithInstancesQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryTimelogWithInstancesResult>): QueryTimelogWithInstancesResult => r.body),
    );
  }

  /** Path part for operation `timelogAccountingInstancesQuery()` */
  static readonly TimelogAccountingInstancesQueryPath = '/timelog/timelog-accounting-instances-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogAccountingInstancesQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingInstancesQuery$Response(
    params: TimelogAccountingInstancesQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInstanceResult>> {
    return timelogAccountingInstancesQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogAccountingInstancesQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingInstancesQuery(
    params: TimelogAccountingInstancesQuery$Params,
    context?: HttpContext,
  ): Observable<QueryInstanceResult> {
    return this.timelogAccountingInstancesQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInstanceResult>): QueryInstanceResult => r.body),
    );
  }

  /** Path part for operation `timelogAccountingWarnings()` */
  static readonly TimelogAccountingWarningsPath = '/timelog/timelog-accounting-warnings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogAccountingWarnings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingWarnings$Response(
    params: TimelogAccountingWarnings$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AccountingWarningsDto>> {
    return timelogAccountingWarnings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogAccountingWarnings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingWarnings(
    params: TimelogAccountingWarnings$Params,
    context?: HttpContext,
  ): Observable<AccountingWarningsDto> {
    return this.timelogAccountingWarnings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingWarningsDto>): AccountingWarningsDto => r.body),
    );
  }

  /** Path part for operation `timelogAccountingInstancesInvoices()` */
  static readonly TimelogAccountingInstancesInvoicesPath = '/timelog/timelog-accounting-instances-invoices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogAccountingInstancesInvoices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingInstancesInvoices$Response(
    params: TimelogAccountingInstancesInvoices$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInvoiceResult>> {
    return timelogAccountingInstancesInvoices(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogAccountingInstancesInvoices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogAccountingInstancesInvoices(
    params: TimelogAccountingInstancesInvoices$Params,
    context?: HttpContext,
  ): Observable<QueryInvoiceResult> {
    return this.timelogAccountingInstancesInvoices$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInvoiceResult>): QueryInvoiceResult => r.body),
    );
  }

  /** Path part for operation `timelog()` */
  static readonly TimelogPath = '/timelog/timelog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelog()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelog$Response(params?: Timelog$Params, context?: HttpContext): Observable<StrictHttpResponse<TimelogEntity>> {
    return timelog(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelog(params?: Timelog$Params, context?: HttpContext): Observable<TimelogEntity> {
    return this.timelog$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogEntity>): TimelogEntity => r.body),
    );
  }

  /** Path part for operation `timelogCollisions()` */
  static readonly TimelogCollisionsPath = '/timelog/timelog-collisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogCollisions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogCollisions$Response(
    params: TimelogCollisions$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<TimelogEntity>>> {
    return timelogCollisions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogCollisions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogCollisions(params: TimelogCollisions$Params, context?: HttpContext): Observable<Array<TimelogEntity>> {
    return this.timelogCollisions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimelogEntity>>): Array<TimelogEntity> => r.body),
    );
  }

  /** Path part for operation `timelogDelete()` */
  static readonly TimelogDeletePath = '/timelog/timelog-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogDelete$Response(
    params: TimelogDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return timelogDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogDelete(params: TimelogDelete$Params, context?: HttpContext): Observable<DeleteResponseDto> {
    return this.timelogDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `timelogValidate()` */
  static readonly TimelogValidatePath = '/timelog/timelog-validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogValidate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogValidate$Response(
    params: TimelogValidate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogValidateResultDto>> {
    return timelogValidate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogValidate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogValidate(params: TimelogValidate$Params, context?: HttpContext): Observable<TimelogValidateResultDto> {
    return this.timelogValidate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogValidateResultDto>): TimelogValidateResultDto => r.body),
    );
  }

  /** Path part for operation `timelogSave()` */
  static readonly TimelogSavePath = '/timelog/timelog-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogSave$Response(
    params: TimelogSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogEntity>> {
    return timelogSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogSave(params: TimelogSave$Params, context?: HttpContext): Observable<TimelogEntity> {
    return this.timelogSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogEntity>): TimelogEntity => r.body),
    );
  }

  /** Path part for operation `timelogBunchSave()` */
  static readonly TimelogBunchSavePath = '/timelog/timelog-bunch-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogBunchSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogBunchSave$Response(
    params: TimelogBunchSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogBunchDto>> {
    return timelogBunchSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogBunchSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogBunchSave(params: TimelogBunchSave$Params, context?: HttpContext): Observable<TimelogBunchDto> {
    return this.timelogBunchSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogBunchDto>): TimelogBunchDto => r.body),
    );
  }

  /** Path part for operation `timelogSuggest()` */
  static readonly TimelogSuggestPath = '/timelog/timelog-suggest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogSuggest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogSuggest$Response(
    params: TimelogSuggest$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogResponseDto>> {
    return timelogSuggest(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogSuggest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogSuggest(params: TimelogSuggest$Params, context?: HttpContext): Observable<TimelogResponseDto> {
    return this.timelogSuggest$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogResponseDto>): TimelogResponseDto => r.body),
    );
  }

  /** Path part for operation `timelogApprove()` */
  static readonly TimelogApprovePath = '/timelog/timelog-approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogApprove$Response(
    params: TimelogApprove$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogApproveInputDto>> {
    return timelogApprove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogApprove(params: TimelogApprove$Params, context?: HttpContext): Observable<TimelogApproveInputDto> {
    return this.timelogApprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogApproveInputDto>): TimelogApproveInputDto => r.body),
    );
  }

  /** Path part for operation `timelogUnapprove()` */
  static readonly TimelogUnapprovePath = '/timelog/timelog-unapprove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogUnapprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogUnapprove$Response(
    params: TimelogUnapprove$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogApproveInputDto>> {
    return timelogUnapprove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogUnapprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogUnapprove(params: TimelogUnapprove$Params, context?: HttpContext): Observable<TimelogApproveInputDto> {
    return this.timelogUnapprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogApproveInputDto>): TimelogApproveInputDto => r.body),
    );
  }

  /** Path part for operation `timelogMonthLocks()` */
  static readonly TimelogMonthLocksPath = '/timelog/timelog-monthlocks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogMonthLocks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthLocks$Response(
    params: TimelogMonthLocks$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryMonthLocksResult>> {
    return timelogMonthLocks(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogMonthLocks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthLocks(params: TimelogMonthLocks$Params, context?: HttpContext): Observable<QueryMonthLocksResult> {
    return this.timelogMonthLocks$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryMonthLocksResult>): QueryMonthLocksResult => r.body),
    );
  }

  /** Path part for operation `timelogMonthLock()` */
  static readonly TimelogMonthLockPath = '/timelog/timelog-month-lock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogMonthLock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthLock$Response(
    params: TimelogMonthLock$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<MonthLockEntity>> {
    return timelogMonthLock(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogMonthLock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthLock(params: TimelogMonthLock$Params, context?: HttpContext): Observable<MonthLockEntity> {
    return this.timelogMonthLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<MonthLockEntity>): MonthLockEntity => r.body),
    );
  }

  /** Path part for operation `timelogMonthUnlock()` */
  static readonly TimelogMonthUnlockPath = '/timelog/timelog-month-unlock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogMonthUnlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthUnlock$Response(
    params: TimelogMonthUnlock$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogMonthLockInputDto>> {
    return timelogMonthUnlock(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogMonthUnlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  timelogMonthUnlock(params: TimelogMonthUnlock$Params, context?: HttpContext): Observable<TimelogMonthLockInputDto> {
    return this.timelogMonthUnlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogMonthLockInputDto>): TimelogMonthLockInputDto => r.body),
    );
  }

  /** Path part for operation `timelogAttachment()` */
  static readonly TimelogAttachmentPath = '/timelog/timelogAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogAttachment$Response(
    params?: TimelogAttachment$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<TimelogAttachment>>> {
    return timelogAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogAttachment(params?: TimelogAttachment$Params, context?: HttpContext): Observable<Array<TimelogAttachment>> {
    return this.timelogAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TimelogAttachment>>): Array<TimelogAttachment> => r.body),
    );
  }

  /** Path part for operation `timelogBunchDocumentation()` */
  static readonly TimelogBunchDocumentationPath = '/timelog-documentation/documentation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `timelogBunchDocumentation()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogBunchDocumentation$Response(
    params: TimelogBunchDocumentation$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TimelogBunchDto>> {
    return timelogBunchDocumentation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `timelogBunchDocumentation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  timelogBunchDocumentation(
    params: TimelogBunchDocumentation$Params,
    context?: HttpContext,
  ): Observable<TimelogBunchDto> {
    return this.timelogBunchDocumentation$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimelogBunchDto>): TimelogBunchDto => r.body),
    );
  }

  /** Path part for operation `saveSetting()` */
  static readonly SaveSettingPath = '/settings/settings-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSetting$Response(params: SaveSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDto>> {
    return saveSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSetting(params: SaveSetting$Params, context?: HttpContext): Observable<SettingDto> {
    return this.saveSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDto>): SettingDto => r.body),
    );
  }

  /** Path part for operation `getSetting()` */
  static readonly GetSettingPath = '/settings/settings-get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSetting$Response(params: GetSetting$Params, context?: HttpContext): Observable<StrictHttpResponse<SettingDto>> {
    return getSetting(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSetting(params: GetSetting$Params, context?: HttpContext): Observable<SettingDto> {
    return this.getSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettingDto>): SettingDto => r.body),
    );
  }

  /** Path part for operation `getAllSettings()` */
  static readonly GetAllSettingsPath = '/settings/settings-get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettings$Response(
    params?: GetAllSettings$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<SettingDto>>> {
    return getAllSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSettings(params?: GetAllSettings$Params, context?: HttpContext): Observable<Array<SettingDto>> {
    return this.getAllSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettingDto>>): Array<SettingDto> => r.body),
    );
  }

  /** Path part for operation `invoiceQuery()` */
  static readonly InvoiceQueryPath = '/invoice/invoice-query';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceQuery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceQuery$Response(
    params: InvoiceQuery$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInvoiceResult>> {
    return invoiceQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceQuery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceQuery(params: InvoiceQuery$Params, context?: HttpContext): Observable<QueryInvoiceResult> {
    return this.invoiceQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInvoiceResult>): QueryInvoiceResult => r.body),
    );
  }

  /** Path part for operation `invoiceList()` */
  static readonly InvoiceListPath = '/invoice/invoice-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceList$Response(params: InvoiceList$Params, context?: HttpContext): Observable<StrictHttpResponse<{}>> {
    return invoiceList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceList(params: InvoiceList$Params, context?: HttpContext): Observable<{}> {
    return this.invoiceList$Response(params, context).pipe(map((r: StrictHttpResponse<{}>): {} => r.body));
  }

  /** Path part for operation `invoiceAgendaList()` */
  static readonly InvoiceAgendaListPath = '/invoice/invoice-agendaList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceAgendaList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceAgendaList$Response(
    params: InvoiceAgendaList$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<{}>> {
    return invoiceAgendaList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceAgendaList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceAgendaList(params: InvoiceAgendaList$Params, context?: HttpContext): Observable<{}> {
    return this.invoiceAgendaList$Response(params, context).pipe(map((r: StrictHttpResponse<{}>): {} => r.body));
  }

  /** Path part for operation `invoiceQueryCustomers()` */
  static readonly InvoiceQueryCustomersPath = '/invoice/invoice-query-customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceQueryCustomers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceQueryCustomers$Response(
    params: InvoiceQueryCustomers$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<QueryInvoiceResult>> {
    return invoiceQueryCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceQueryCustomers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceQueryCustomers(params: InvoiceQueryCustomers$Params, context?: HttpContext): Observable<QueryInvoiceResult> {
    return this.invoiceQueryCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueryInvoiceResult>): QueryInvoiceResult => r.body),
    );
  }

  /** Path part for operation `invoiceCreate()` */
  static readonly InvoiceCreatePath = '/invoice/invoice-create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceCreate$Response(
    params: InvoiceCreate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<InvoiceDto>>> {
    return invoiceCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceCreate(params: InvoiceCreate$Params, context?: HttpContext): Observable<Array<InvoiceDto>> {
    return this.invoiceCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InvoiceDto>>): Array<InvoiceDto> => r.body),
    );
  }

  /** Path part for operation `invoiceBunchSave()` */
  static readonly InvoiceBunchSavePath = '/invoice/invoice-bunch-save';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceBunchSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceBunchSave$Response(
    params: InvoiceBunchSave$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InvoiceBunchDto>> {
    return invoiceBunchSave(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceBunchSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceBunchSave(params: InvoiceBunchSave$Params, context?: HttpContext): Observable<InvoiceBunchDto> {
    return this.invoiceBunchSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceBunchDto>): InvoiceBunchDto => r.body),
    );
  }

  /** Path part for operation `invoiceDelete()` */
  static readonly InvoiceDeletePath = '/invoice/invoice-delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceDelete$Response(
    params: InvoiceDelete$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DeleteResponseDto>> {
    return invoiceDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoiceDelete(params: InvoiceDelete$Params, context?: HttpContext): Observable<DeleteResponseDto> {
    return this.invoiceDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteResponseDto>): DeleteResponseDto => r.body),
    );
  }

  /** Path part for operation `invoiceBunch()` */
  static readonly InvoiceBunchPath = '/pdf/i/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoiceBunch()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceBunch$Response(
    params: InvoiceBunch$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<InvoiceBunchDto>> {
    return invoiceBunch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoiceBunch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoiceBunch(params: InvoiceBunch$Params, context?: HttpContext): Observable<InvoiceBunchDto> {
    return this.invoiceBunch$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvoiceBunchDto>): InvoiceBunchDto => r.body),
    );
  }
}
