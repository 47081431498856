/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { MonthLockEntity } from '../../models/month-lock-entity';
import { TimelogMonthLockInputDto } from '../../models/timelog-month-lock-input-dto';

export interface TimelogMonthLock$Params {
  body: TimelogMonthLockInputDto;
}

export function timelogMonthLock(
  http: HttpClient,
  rootUrl: string,
  params: TimelogMonthLock$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<MonthLockEntity>> {
  const rb = new RequestBuilder(rootUrl, timelogMonthLock.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<MonthLockEntity>;
    }),
  );
}

timelogMonthLock.PATH = '/timelog/timelog-month-lock';
