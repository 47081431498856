import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, forkJoin, map, of, tap } from 'rxjs';
import { IConfig } from './config.interfaces';
import { NamedCauseError } from './named-cause-error';

export class ConfigServiceError extends NamedCauseError {
  constructor(message: string) {
    super('ConfigServiceError', message);
  }
}

@Injectable()
export class ConfigService {
  protected readonly _config$ = new BehaviorSubject<IConfig>({
    apiUrl: '',
    error: undefined,
    logoUrl: '',
    title: 'Default Title',
    version: '',
  });

  /**
   * Observable of the current config
   */
  public readonly config$ = this._config$.asObservable();

  /**
   * Current config
   */
  public get config(): IConfig {
    return this._config$.value;
  }

  /**
   * Fetch the config from the server
   */
  private readonly fetchConfig$ = this.http.get<Partial<IConfig>>('/config.json');

  /**
   * Fetch the version from the server
   */
  private readonly fetchVersion$ = this.http.get('/version.txt', { responseType: 'text' }).pipe(
    map((version) => version.trim()),
    map((version) => {
      // Check if version matches out pattern (ex: [dv|pr].[major].[minor].[patch].[build])
      // On vite dev server, response is index.html content
      if (/^[\w]{2}[0-9.]+$/.test(version)) {
        return version;
      }
      throw new Error('Invalid version');
    }),
    catchError(() => of('local')),
  );

  constructor(private readonly http: HttpClient) {}

  init(): Observable<IConfig> {
    return forkJoin({
      config: this.fetchConfig$,
      version: this.fetchVersion$,
    }).pipe(
      map(({ config, version }) => ({ ...config, version }) as IConfig),
      catchError((err: HttpErrorResponse) => {
        return of({
          error: new ConfigServiceError(`Http failure response for ${err.url}: ${err.status}`),
          apiUrl: '',
          version: '',
          logoUrl: '',
          title: 'Default Title',
        });
      }),
      tap((config) => this._config$.next(config)),
    );
  }
}
