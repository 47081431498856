/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { InstancePeriodDto } from '../../models/instance-period-dto';
import { InstancePeriodStatsQueryDto } from '../../models/instance-period-stats-query-dto';

export interface InstancePeriodsStats$Params {
  body: InstancePeriodStatsQueryDto;
}

export function instancePeriodsStats(
  http: HttpClient,
  rootUrl: string,
  params: InstancePeriodsStats$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<InstancePeriodDto>>> {
  const rb = new RequestBuilder(rootUrl, instancePeriodsStats.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<InstancePeriodDto>>;
    }),
  );
}

instancePeriodsStats.PATH = '/instance/instance-periods-stats';
