/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { DeleteResponseDto } from '../../models/delete-response-dto';
import { InstanceWageSaveInput } from '../../models/instance-wage-save-input';

export interface InstanceWageDelete$Params {
  body: InstanceWageSaveInput;
}

export function instanceWageDelete(
  http: HttpClient,
  rootUrl: string,
  params: InstanceWageDelete$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<DeleteResponseDto>> {
  const rb = new RequestBuilder(rootUrl, instanceWageDelete.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DeleteResponseDto>;
    }),
  );
}

instanceWageDelete.PATH = '/instance/instance-wage-delete';
