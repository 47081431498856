/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { TimelogBunchDataDto } from '../../models/timelog-bunch-data-dto';
import { TimelogBunchDto } from '../../models/timelog-bunch-dto';

export interface TimelogBunchSave$Params {
  body: TimelogBunchDataDto;
}

export function timelogBunchSave(
  http: HttpClient,
  rootUrl: string,
  params: TimelogBunchSave$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<TimelogBunchDto>> {
  const rb = new RequestBuilder(rootUrl, timelogBunchSave.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TimelogBunchDto>;
    }),
  );
}

timelogBunchSave.PATH = '/timelog/timelog-bunch-save';
