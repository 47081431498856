/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { LoginDto } from '../../models/login-dto';
import { TwoFactorEnablingResult } from '../../models/two-factor-enabling-result';

export interface AuthEnable2Fa$Params {
  body: LoginDto;
}

export function authEnable2Fa(
  http: HttpClient,
  rootUrl: string,
  params: AuthEnable2Fa$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<TwoFactorEnablingResult>> {
  const rb = new RequestBuilder(rootUrl, authEnable2Fa.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TwoFactorEnablingResult>;
    }),
  );
}

authEnable2Fa.PATH = '/auth/auth-enable-2fa';
