/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RequestBuilder } from '../../request-builder';
import { StrictHttpResponse } from '../../strict-http-response';

import { AgentAccessibleDto } from '../../models/agent-accessible-dto';
import { QueryAgentResult } from '../../models/query-agent-result';

export interface AgentAccessible$Params {
  body: AgentAccessibleDto;
}

export function agentAccessible(
  http: HttpClient,
  rootUrl: string,
  params: AgentAccessible$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<QueryAgentResult>> {
  const rb = new RequestBuilder(rootUrl, agentAccessible.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<QueryAgentResult>;
    }),
  );
}

agentAccessible.PATH = '/agent/agent-accessible';
