import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { AuthDto } from './generated/api/models';

const store = createStore({ name: 'auth' }, withProps<AuthProps>({}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const persist = persistState(store, {
  key: 'auth',
  storage: localStorageStrategy,
});

interface AuthProps extends AuthDto {}

@Injectable()
export class AuthRepository {
  constructor() {}

  auth$ = store.pipe(select((state) => state));

  isLoggedIn$ = store.pipe(select((state) => !!state.token));

  bearer$ = store.pipe(select((state) => `Bearer ${state.token}`));

  updateAuth(auth: AuthDto) {
    store.update(() => ({ ...auth }));
  }
}
